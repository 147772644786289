import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PubliftInit() {
    /*
    
    const location = useLocation();
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const isPro = useSelector(state => state.me.is_pro);

    // Initialize Publift on first page load
    useEffect(() => {
        if (!(isLoggedIn && isPro)) {
            // Get an initialization-safe reference to the Fuse Queue
            const fusetag = window.fusetag || (window.fusetag = { que: [] });

            fusetag.que.push(function () {
                // Tell Fuse the page is ready, with optional blocking FuseIds
                fusetag.pageInit({
                    // Add any blocking FuseIds that must be registered before auction
                    // blockingFuseIds: ['yourFuseId1', 'yourFuseId2'],

                    pageTargets: [
                        {
                            'key': 'site',
                            'value': 'musicboard',
                        },
                        {
                            'key': 'path',
                            'value': location.pathname,
                        }
                    ]
                });
            });
        } 
        else {
            //Remove publift if is pro
            const publiftScript = document.getElementById('publift-script');
            if (publiftScript) {
                publiftScript.remove();
            }
            const stickyFooters = document.getElementsByClassName('publift-widget-sticky_footer-container');
            const stickyFooter = stickyFooters[0];
            if (stickyFooter) {
                stickyFooter.remove();
            }
        }
        
    }, [isLoggedIn, isPro]);

    // Re-initialize on route changes
    useEffect(() => {
        if (!(isLoggedIn && isPro)) {
            // Skip first load (handled by first useEffect)
            const handleRouteChange = () => {
                const fusetag = window.fusetag || (window.fusetag = { que: [] });
    
                fusetag.que.push(function () {
                    fusetag.pageInit({
                        pageTargets: [
                            {
                                'key': 'site',
                                'value': 'musicboard',
                            },
                            {
                                'key': 'path',
                                'value': location.pathname,
                            }
                        ]
                    });
                });
            };

            handleRouteChange();
        }
        else {
            //Remove publift if is pro
            const publiftScript = document.getElementById('publift-script');
            if (publiftScript) {
                publiftScript.remove();
            }
            const stickyFooters = document.getElementsByClassName('publift-widget-sticky_footer-container');
            const stickyFooter = stickyFooters[0];
            if (stickyFooter) {
                stickyFooter.remove();
            }
        }

    }, [location, isLoggedIn, isPro]);

    */

    return null; // This component doesn't render anything
}

export default PubliftInit;