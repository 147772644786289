import './assets/css/normalize.css';
import './assets/css/fonts.css';
import './assets/css/style.css';
import './assets/css/date-picker-overrides.css';

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import Cookies from 'js-cookie';

import { store, persistor } from './store';

import Segment from './shared/Segment';
import Navigator from './shared/Navigator';
import CacheData from './shared/CacheData';
import SidebarAds from './shared/SidebarAds';
import AuthWrapper from './shared/AuthWrapper';
import HealthCheck from './shared/HealthCheck';
import ScrollToTop from './shared/ScrollToTop';
import PubliftInit from './shared/PubliftInit';
import ErrorHandler from './shared/ErrorHandler';
import GeneralFetching from './shared/GeneralFetching';
import StatusAlertHandler from './shared/StatusAlertHandler';

import ThemeProvider from './hooks/theme';
import PopupProvider from './hooks/popup/popup';
import AlertProvider from './hooks/alert/alert';
import AudioProvider from './hooks/audio/audio';
import ScrollbarProvider from './hooks/scrollbar';
import PlayVideoProvider from './hooks/play-video/play-video';

if (process.env.REACT_APP_STAGE === 'production') {
	console.log = console.info = console.error = console.warn = console.debug = console.trace = () => {};
}

const hiddenPaths = [
    ["/about"],
    ["/faq/storage"],
    ["/contact"],
    ["/contributors/artists"],
    ["/contributors"],
    ["/contributors/releases"],
    ["/album/new"],
    ["/artist/new"],
    ["/list/new"],
    ["/new"],
    ["/faq"],
    ["/groups"],
    ["/guidelines"],
    ["/onboarding"],
    ["/onboarding/personal"],
    ["/onboarding/genres"],
    ["/onboarding/albums"],
    ["/onboarding/users"],
    ["/onboarding/features"],
    ["/patch-notes"],
    ["/privacy"],
    ["/download"],
    ["/pro"],
    ["/reset-password"],
    ["/settings"],
    ["/settings/preferences"],
    ["/settings/billing"],
    ["/guidelines"],
    ["/terms"],
    ["/pro/complete"],
    ["/verify-email"],
    ["/album", "/edit"],
    ["/artist", "/edit"],
    ["/list", "/edit"],
]

const hiddenLoginPaths = [
    ["/lists/following"],
    ["/reviews/following"],
    ["/ratings/following"],
]

function BottomRailSpacer() {
	//General
	const location = useLocation();

	//Auth
	const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const isPro = useSelector(state => state.me.is_pro);

	//State
	const [height, setHeight] = useState(0);

	const skipTaglessUnits = () => {
        for (let i = 0; i < hiddenPaths.length; i++) {
            const path = hiddenPaths[i]
            if (path.length === 1 && location.pathname === path[0]) {
                return true
            } else if (path.length === 2 && location.pathname.includes(path[0]) && location.pathname.includes(path[1])) {
                return true
            }
        }
        for (let i = 0; i < hiddenLoginPaths.length; i++) {
            const path = hiddenLoginPaths[i]
            if (path.length === 1 && location.pathname === path[0] && !isLoggedIn) {
                return true
            }
        }
        return false;
    }

	useEffect(() => {
		// Check if we should skip the sticky footer
		const shouldSkip = skipTaglessUnits() || (isLoggedIn && isPro);
		
		// If we should skip, hide any existing sticky footer
		if (shouldSkip) {
			const elements = document.getElementsByClassName('publift-widget-sticky_footer-container');
			const element = elements[0];
			if (element) {
				element.style.display = 'none';
				setHeight(0);
			}
		}

		// Set up resize observer to watch for height changes
		const resizeObserver = new ResizeObserver(entries => {
			const entry = entries[0];
			if (entry) {
				setHeight(entry.contentRect.height);
			}
		});

		// Set up mutation observer to watch for element being added/removed
		const mutationObserver = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === 'childList') {
					const elements = document.getElementsByClassName('publift-widget-sticky_footer-container');
					const element = elements[0];
					if (element) {
						if (shouldSkip) {
							element.style.display = 'none';
							setHeight(0);
						} else {
							element.style.display = '';
							resizeObserver.observe(element);
						}
					} else {
						setHeight(0);
					}
				}
			});
		});

		// Start observing DOM changes
		mutationObserver.observe(document.body, {
			childList: true,
			subtree: true
		});

		// Check if element exists initially
		const elements = document.getElementsByClassName('publift-widget-sticky_footer-container');
		const element = elements[0];
		if (element) {
			if (shouldSkip) {
				element.style.display = 'none';
				setHeight(0);
			} else {
				element.style.display = '';
				resizeObserver.observe(element);
			}
		} else {
			setHeight(0);
		}

		// Cleanup
		return () => {
			resizeObserver.disconnect();
			mutationObserver.disconnect();
		};
	}, [location.pathname, isLoggedIn, isPro]);

	return <div style={{ height }} />;
}

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<GoogleOAuthProvider clientId="312560736458-88o2l36f9sg2bhd4udnnt0c75s4sqtmj.apps.googleusercontent.com">
					<ThemeProvider>
						<ScrollbarProvider>
							<ErrorHandler>
								<PlayVideoProvider>
									<Router>
										<CacheData>
											<ScrollToTop>
												<AlertProvider>
													<PopupProvider>
														<AudioProvider>
															<HealthCheck>	
																<StatusAlertHandler>
																	<Segment>
																		<AuthWrapper>
																			<GeneralFetching>
																				<PubliftInit />

																				<Navigator />

																				<SidebarAds />
																				
																				<BottomRailSpacer />
																			</GeneralFetching>
																		</AuthWrapper>
																	</Segment>
																</StatusAlertHandler>
															</HealthCheck>
														</AudioProvider>
													</PopupProvider>
												</AlertProvider>
											</ScrollToTop>
										</CacheData>
									</Router>
								</PlayVideoProvider>
							</ErrorHandler>
						</ScrollbarProvider>
					</ThemeProvider>
				</GoogleOAuthProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;
