import styles from './artisthome.module.css'

import React, { Suspense, lazy, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { status, types } from '../../../../constants/action-types'

import spotifyAppIcon from '../../../../assets/images/streaming/spotify-svg.svg'
import appleMusicAppIcon from '../../../../assets/images/streaming/apple-music-svg.svg'
import youtubeMusicAppIcon from '../../../../assets/images/streaming/youtube-music-svg.svg'
import deezerAppIcon from '../../../../assets/images/streaming/deezer-svg.svg'
import soundcloudAppIcon from '../../../../assets/images/streaming/soundcloud-svg.svg'
import bandcampAppIcon from '../../../../assets/images/streaming/bandcamp-svg.svg'

import { createWant, deleteWant } from '../../../../actions/me-actions'
import { fetchTracksForArtist, getArtistDiscography, getArtistLists, getArtistRatingDistribution, getArtistRatings, getArtistReviews, getArtistTopTracks } from '../../../../actions/content-actions'

import { createArtistMusicGroupSchema, createBreadcrumbSchema } from '../../../../data/structured-data'

import { useDeezer } from '../../../../hooks/deezer'
import { useSpotify } from '../../../../hooks/spotify'
import { useAudio } from '../../../../hooks/audio/audio'
import { usePopup } from '../../../../hooks/popup/popup'

import { filterAndFill } from '../../../../utils/format-array'
import { numberWithCommas } from '../../../../utils/format-text'

import ListItemLoader from '../../../../loaders/ListItemLoader'
import TrackItemLoader from '../../../../loaders/TrackItemLoader'
import ReviewItemLoader from '../../../../loaders/ReviewItemLoader'
import RatingDistributionLoader from '../../../../loaders/RatingDistributionLoader'

import DefaultHelmet from '../../../../shared/DefaultHelmet'
import StructuredData from '../../../../shared/StructuredData'

import Icon from '../../../../components/Icon'
import Button from '../../../../components/Button'
import Heading from '../../../../components/Heading'
import ListItem from '../../../../components/ListItem'
import TrackItem from '../../../../components/TrackItem'
import ReviewItem from '../../../../components/ReviewItem'
import MiniFooter from '../../../../components/MiniFooter'
import OptionsMenu from '../../../../components/OptionsMenu'
import PubliftAd from '../../../../components/PubliftAd'
import RatingsGraph from '../../../../components/RatingsGraph'
import ShareElement from '../../../../components/ShareElement'
import UserRatingItem from '../../../../components/UserRatingItem'
import BigReleaseItem from '../../../../components/BigReleaseItem'
import SmallArtistItem from '../../../../components/SmallArtistItem'
import StickyScrolling from '../../../../components/StickyScrolling'

import ArtistInformationSection from '../ArtistInformationSection';
import Tooltip from '../../../../components/Tooltip'

export default function ArtistHome({ backend, biography, titleSlug }) {
    //General
    const dispatch = useDispatch()
    const deezer = useDeezer()
    const spotifyFetch = useSpotify()
    const { openPopup } = usePopup()
    const { playTracks } = useAudio()

    //Responsive
    const isMidScreen = useMediaQuery({ maxWidth: 1100 })
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isTablet = useMediaQuery({ maxWidth: 768 })
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Auth
    const streaming_service = useSelector(state => state.me.streaming_service)

    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const isModerator = useSelector(state => state.me.is_moderator)
    const isDatabaseModerator = useSelector(state => state.me.is_database_moderator)

    //Wants
    const deleteWantStatus = useSelector(state => state.me.deleteWantStatus)
    const lastDeletedWantContentId = useSelector(state => state.me.lastDeletedWantContentId)
    const myWants = useSelector(state => state.me.wants)
    const cachedWants = useSelector(state => state.me.cachedWants)
    const wants = [ ...myWants, ...cachedWants ]
    const wantIndex = wants.findIndex(want => want.content_id == backend.id && want.content_ct === "artist")
    const wantObj = wantIndex !== -1 ? wants[wantIndex] : null

    //State
    const [related, setRelated] = useState(null)
    const [top, setTop] = useState(null)
    const [spotify, setSpotify] = useState(null)

    //Get data
    const hasFetched = useRef(false)
    useEffect(() => {
        if (backend && backend.id && !hasFetched.current) {
            hasFetched.current = true;
            
            //Get artist's rating distribution
            dispatch(
                getArtistRatingDistribution(backend.id)
            )

            //Fetch top lists
            dispatch( 
                getArtistLists({
                    id: backend.id,
                    order_by: "-like_count",
                    limit: 3,
                    type: types.GET_ARTISTS_HOME_LISTS,
                })
            )

            //Get reviews
            dispatch( 
                getArtistReviews({
                    id: backend.id,
                    scored: true,
                    order_by: "",
                    limit: 6,
                    type: types.GET_ARTISTS_HOME_REVIEWS,
                }) 
            )

            //Get friends ratings
            if (isLoggedIn) {
                dispatch(
                    getArtistRatings({
                        id: backend.id,
                        following: true
                    })
                )
            }
    
            //Fetch the artist's discography (if we haven't already)
            if (!(backend.discography && backend.discography.data && backend.discography.data.length >= 0)) {
                dispatch(
                    getArtistDiscography(backend.id, backend.deezer_id)
                )
            }
    
            if (!(backend.topTracks && backend.topTracks.data && backend.topTracks.data.length >= 0)) {
                //Get top tracks from Deezer
                dispatch(
                    getArtistTopTracks(backend.id)
                )
            }

            //Get related artist from Deezer
            if (backend.deezer_id) {
                deezer.getArtistRelated(backend.deezer_id).then((res) => {
                    setRelated(res)
                }).catch((err) => {
                    setRelated({ data: [] })
                })
            }
            
            //Get spotify link
            spotifyFetch.searchArtist(backend.name).then((res) => {
                setSpotify(res.artists.items[0].external_urls.spotify)
            }).catch((err) => {
                console.warn("Spotify error", { err })
            })

        }

    }, [ backend ])

    //Helper functions
    const playTracksCallback = useCallback((trackId) => {
        // Render tracks from Deezer
        if (backend.deezer_id && backend.topTracks?.data) {
            playTracks(
                backend.topTracks.data
                .filter((track) => track.preview)
                .map((track) => ({
                    id: track.id, 
                    deezer_id: track.deezer_id, 
                    url_slug: track.url_slug, 
                    name: track.title, 
                    singer: track.artist.name, 
                    cover: track.album.cover !== null && track.album.cover !== "" ? track.album.cover_medium || track.album.cover : backend.picture_medium || backend.picture, 
                    musicSrc: track.preview,
                    duration: track.duration,
                    album: {
                        id: track.album.id,
                    },
                    artist: {
                        id: backend.id,
                        deezer_id: backend.deezer_id,
                        url_slug: backend.url_slug,
                    }
                })),
                backend.topTracks.data.findIndex((track) => track.id == trackId)
            )
        }
    }, [backend.topTracks])

    //Variables
    const activeTracks = backend.topTracks?.data ? backend.topTracks.data : []

    return (
        <div className="page-container">
            <DefaultHelmet
            title={`${backend.name}${titleSlug?.match(/\-\d+$/) ? ` - ${titleSlug.match(/\-(\d+)$/)[1]}` : ''} - Musicboard`}
            canonical={`https://musicboard.app${backend.url_slug}`}
            description={`${backend.name} discography, songs, reviews, and ratings. Music profile for ${backend.name}, ${backend.is_group ? "formed" : "born"} ${backend.birth_year ? `on ${moment(backend.birth_year).format(backend.birth_year_precision === "year" ? "YYYY" : backend.birth_year_precision === "month" ? "MMMM YYYY" : "DD MMMM YYYY")}` : ""} ${backend.country && backend.country !== "" ? `in ${backend.country}` : ""}. Discography: ${backend.nb_albums} releases. Rated ${(backend.average_rating / 2).toFixed(2)} / 5 by ${numberWithCommas(backend.ratings_count || 0)} listeners. Explore their entire profile on Musicboard.`}
            ogImage={backend.picture_large || backend.picture}
            ogImageAlt={backend.name}
            ogImageWidth={500}
            ogImageHeight={500}
            twitterImage={backend.picture_large || backend.picture}
            twitterImageWidth={500}
            twitterImageHeight={500}
            labelOne={
                backend.country && backend.country !== "" ?
                    { label: "Country", data: backend.country }
                : backend.birth_year && backend.birth_year !== "" ?
                    { label: "Born", data: moment(backend.birth_year).format(backend.birth_year_precision === "year" ? "YYYY" : backend.birth_year_precision === "month" ? "MMM YYYY" : "DD MMM YYYY") }
                :
                    { label: "Number of Releases", data: `${backend.nb_albums} releases`}
            }
            labelTwo={{ label: "Average Rating", data: `${(backend.average_rating / 2).toFixed(2)} out of 5` }}
            deepLink={`musicboard://artist/${backend.id}`}
            headingOne={`${backend.name}`}
            headingTwo={`Reviews & Ratings on Musicboard`}
            />

            {(backend.homeReviews && backend.homeReviews.data && backend.homeReviews.data.length > 0) ?
                <StructuredData
                key={`Artist-MusicArtist-Data-${backend.id}`}
                id={`Artist-MusicArtist-Data-${backend.id}`}
                data={createArtistMusicGroupSchema(backend, backend.homeReviews.data.filter((review) => review.rating?.rating !== null))}
                />
            : null}

            <StructuredData
            key={`Artist-Breadcrumb-Data-${backend.id}`}
            id={`Artist-Breadcrumb-Data-${backend.id}`}
            data={createBreadcrumbSchema([
                { "name": "Musicboard", "item": "https://musicboard.app/" },
                { "name": "Artists", "item": "https://musicboard.app/artists/" },
                { "name": backend.name, "item": `https://musicboard.app${backend.url_slug}` }
            ])}
            />

            <PubliftAd
            fuseId="trending_header"
            className="margin-top-32"
            type="full"
            adChannel="Artist/LeftFirst"
            />

            <div className="section-handler">
                {/* LEFT SIDE */}
                <div className="section-2 section-margin">
                    
                    <Heading
                    text="Most Popular Tracks"
                    className="margin-top-32"
                    />

                    {activeTracks.length > 0 &&
                        <div className="section-block padding-around-8">
                            {activeTracks.map((item, index) => (
                                <TrackItem
                                track={item}
                                album={item.album}
                                artist={backend}
                                number={index+1}
                                key={`artisthome-track-${item.id}`}
                                playTracks={playTracksCallback}
                                />
                            ))}
                        </div>
                    }

                    {activeTracks.length <= 0 &&
                        <div className="section-block padding-around-8 flex-column">
                            <TrackItemLoader />
                            <TrackItemLoader />
                            <TrackItemLoader />
                            <TrackItemLoader />
                            <TrackItemLoader />
                        </div>
                    }
                    
                    {backend.discography && backend.discography.data && backend.discography.data.length > 0 &&
                        <Heading
                        text="Discography"
                        redirect={`${backend.url_slug}releases`}
                        className="margin-top-32"
                        />
                    }

                    {backend.discography && backend.discography.data && backend.discography.data.length > 0 &&
                        <div className={`grid-view ${isMobile ? "three" : isSmallScreen ? "four" : isMidScreen ? "three" : "four"} section-block padding-around`}>
                            {backend.discography.data.filter((_, i) => i < 8).map((item, index) => (
                                <div key={`artisthome-release-${index}`}>
                                    {item &&
                                        <BigReleaseItem release={item} />
                                    }
                                </div>
                            ))}
                        </div>
                    }

                    <PubliftAd
                    fuseId="trending_incontent_1"
                    className="margin-top-32"
                    type="large"
                    adChannel="Artist/LeftFirst"
                    />

                    {backend && backend.ratings && backend.ratings.data && backend.ratings.data.length > 0 &&
                        <Heading
                        text="Friends Ratings"
                        redirect={`${backend.url_slug}ratings?following=true`}
                        className="margin-top-32"
                        />
                    }

                    {backend && backend.ratings && backend.ratings.data && backend.ratings.data.length > 0 &&
                        <div className={`grid-view ${isMobile ? "four" : isTablet ? "six" : isSmallScreen ? "eight" : isMidScreen ? "six" : "eight"} section-block padding-around`}>
                            {filterAndFill(backend.ratings.data, isMobile ? 8 : isTablet ? 6 : isSmallScreen ? 8 : isMidScreen ? 6 : 8).map((item, index) => (
                                <div key={`artisthome-userrating-${item ? item.uid : index}`}>
                                    {item &&
                                        <UserRatingItem rating={item} />
                                    }
                                </div>
                            ))}
                        </div>
                    }
                    
                    <Heading
                    text="Top Reviews"
                    redirect={`${backend.url_slug}reviews?order_by=helpful`}
                    className="margin-top-32"
                    />

                    <div className="section-block">
                        {backend && backend.homeReviews && backend.homeReviews.data && backend.homeReviews.data.length > 0 && backend.homeReviews.data.filter((_, i) => i < 6).map((item, index) => (
                            <ReviewItem
                            review={item}
                            hideUser
                            presentUserReview
                            isFirst={index === 0}
                            isLast={index === backend.homeReviews.data.filter((_, i) => i < 6).length-1}
                            key={`artisthome-review-${item.uid}`}
                            />
                        ))}

                        {backend && backend.homeReviews && backend.homeReviews.data && backend.homeReviews.data.length <= 0 && backend.homeReviews.status !== status.BEGIN &&
                            <div className="empty-text">
                                <p className="text-center highDarkGrey">Seems like no one has gotten to this yet! Be the first to write a review for {backend.name}.</p>

                                <div className="flex-center">
                                    <Button
                                    text={isLoggedIn ? "Write a review" : "Sign up to write a review"}
                                    iconName={isLoggedIn ? 'edit' : "lock"}
                                    showIcon
                                    onClicked={() => {
                                        if (isLoggedIn) {
                                            openPopup("CreateReview", { content: backend, cacheRating: true })
                                        } else {
                                            openPopup("SignUp")
                                        }
                                    }}
                                    className="margin-top-16"
                                    style={{
                                        paddingLeft: 48,
                                        paddingRight: 48
                                    }}
                                    />
                                </div>
                            </div>
                        }

                        {backend && backend.homeReviews && backend.homeReviews.data && backend.homeReviews.data.length <= 0 && backend.homeReviews.status === status.BEGIN &&
                            [0,1,2,3].map((i) => (
                                <div key={`album-reviews-loader-${i}`} style={{ width: '100%', borderTop: i !== 0 ? '1px solid var(--lightGrey)' : '' }}>
                                    <ReviewItemLoader roundImage />
                                </div>
                            ))
                        }
                    </div>

                    <PubliftAd
                    fuseId="trending_incontent_1"
                    className="margin-top-32"
                    type="large"
                    adChannel="Artist/LeftSecond"
                    />

                    {((backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length > 0) || backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length <= 0 && backend.homeLists.status === status.BEGIN) && 
                        <Heading
                        text={`Lists With ${backend.name}`}
                        redirect={`${backend.url_slug}lists?order_by=-like_count`}
                        className="margin-top-32"
                        />
                    }

                    {((backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length > 0) || backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length <= 0 && backend.homeLists.status === status.BEGIN) && 
                        <div className="section-block">
                            {backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length > 0 && 
                            backend.homeLists.data.filter((_, i) => i < 3).map((item, index) => (
                                <ListItem
                                list={item}
                                isFirst={index === 0}
                                isLast={index === backend.homeLists.data.filter((_, i) => i < 3).length-1}
                                key={`artistList-${backend.id}-${item.uid}`}
                                />
                            ))}

                            {backend && backend.homeLists && backend.homeLists.data && backend.homeLists.data.length <= 0 && backend.homeLists.status === status.BEGIN &&
                                [0,1,2,3].map((i) => (
                                    <div key={`artist-lists-loader-${i}`} style={{ width: '100%', borderTop: i !== 0 ? '1px solid var(--lightGrey)' : '' }}>
                                        <ListItemLoader roundImage />
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
                
                {/* RIGHT SIDE */}
                <div className="section-1">
                    <StickyScrolling enabled={!isSmallScreen}>
                        <div className="padding-top-32" />
                        
                        <OptionsMenu
                        options={[
                            {
                                text: "Write review",
                                onPress: () => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                        return
                                    }

                                    openPopup("CreateReview", { content: backend, cacheRating: true })
                                },
                            },
                            {
                                text: wantObj ? "Remove from Listen Later" : "Add to Listen Later",
                                onPress: () => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                        return
                                    }

                                    if (wantObj) {
                                        dispatch(
                                            deleteWant(wantObj.uid)
                                        )
                                    } else {
                                        dispatch(
                                            createWant(backend.id, "artist", false)
                                        )
                                    }
                                },
                                icon: (deleteWantStatus === status.BEGIN && lastDeletedWantContentId == backend.id) ? "loading" : null,
                            },
                            {
                                text: "Add artist to a list",
                                onPress: () => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                        return
                                    }

                                    openPopup("AddToList", { popupTitle: `Add '${backend.name}' to a List`, content: backend })
                                },
                            },
                            {
                                text: "Send on Musicboard",
                                onPress: () => {
                                    if (!isLoggedIn) {
                                        openPopup("SignUp")
                                        return
                                    }

                                    openPopup("SendContent", { type: "artist", content: backend })
                                },
                            },
                            {
                                element: (
                                    <div className='flex-center'>
                                        {(
                                            (streaming_service === 0 && backend.spotify_link !== "") ||
                                            (streaming_service === 1 && backend.apple_music_link !== "") ||
                                            (streaming_service === 2 && backend.youtube_music_link !== "") ||
                                            (streaming_service === 3 && backend.deezer_link !== "") ||
                                            (streaming_service === 4 && backend.soundcloud_link !== "") ||
                                            (streaming_service === 5 && backend.bandcamp_link !== "")
                                        ) &&
                                            <img
                                            src={
                                                streaming_service === 0 && backend.spotify_link !== "" ?
                                                    spotifyAppIcon
                                                : streaming_service === 1 && backend.apple_music_link !== "" ?
                                                    appleMusicAppIcon
                                                : streaming_service === 2 && backend.youtube_music_link !== "" ?
                                                    youtubeMusicAppIcon
                                                : streaming_service === 3 && backend.deezer_link !== "" ?
                                                    deezerAppIcon
                                                : streaming_service === 4 && backend.soundcloud_link !== "" ?
                                                    soundcloudAppIcon
                                                : streaming_service === 5 && backend.bandcamp_link !== "" ?
                                                    bandcampAppIcon
                                                : 
                                                    null
                                            }
                                            className={styles.streamingServiceIcon}
                                            />
                                        }
                                        
                                        {!(
                                            (streaming_service === 0 && backend.spotify_link !== "") ||
                                            (streaming_service === 1 && backend.apple_music_link !== "") ||
                                            (streaming_service === 2 && backend.youtube_music_link !== "") ||
                                            (streaming_service === 3 && backend.deezer_link !== "") ||
                                            (streaming_service === 4 && backend.soundcloud_link !== "") ||
                                            (streaming_service === 5 && backend.bandcamp_link !== "")
                                        ) &&
                                            <Icon icon="play-circle" size={20} color={"var(--black)"} className='margin-right-8' />
                                        }
                                        
                                        <h5
                                        className={`${styles.text}`}
                                        >
                                            Listen on{" "}
                                            {streaming_service === 0 && backend.spotify_link !== "" ?
                                                "Spotify"
                                            : streaming_service === 1 && backend.apple_music_link !== "" ?
                                                "Apple Music"
                                            : streaming_service === 2 && backend.youtube_music_link !== "" ?
                                                "Youtube Music"
                                            : streaming_service === 3 && backend.deezer_link !== "" ?
                                                "Deezer"
                                            : streaming_service === 4 && backend.soundcloud_link !== "" ?
                                                "Soundcloud"
                                            : streaming_service === 5 && backend.bandcamp_link !== "" ?
                                                "Bandcamp"
                                            : "Streaming"}
                                        </h5>
                                    </div>
                                ),
                                redirect: 
                                    streaming_service === 0 && backend.spotify_link !== "" ?
                                        backend.spotify_link
                                    : streaming_service === 1 && backend.apple_music_link !== "" ?
                                        backend.apple_music_link
                                    : streaming_service === 2 && backend.youtube_music_link !== "" ?
                                        backend.youtube_music_link
                                    : streaming_service === 3 && backend.deezer_link !== "" ?
                                        backend.deezer_link
                                    : streaming_service === 4 && backend.soundcloud_link !== "" ?
                                        backend.soundcloud_link
                                    : streaming_service === 5 && backend.bandcamp_link !== "" ?
                                        backend.bandcamp_link
                                    : null,
                                onPress: 
                                    !(
                                        (streaming_service === 0 && backend.spotify_link !== "") ||
                                        (streaming_service === 1 && backend.apple_music_link !== "") ||
                                        (streaming_service === 2 && backend.youtube_music_link !== "") ||
                                        (streaming_service === 3 && backend.deezer_link !== "") ||
                                        (streaming_service === 4 && backend.soundcloud_link !== "") ||
                                        (streaming_service === 5 && backend.bandcamp_link !== "")
                                    ) ?
                                        () => {
                                            const links = [
                                                {
                                                    url: backend.spotify_link,
                                                    label: "Spotify",
                                                    icon: spotifyAppIcon,
                                                },
                                                {
                                                    url: backend.apple_music_link,
                                                    label: "Apple Music",
                                                    icon: appleMusicAppIcon,
                                                },
                                                {
                                                    url: backend.youtube_music_link,
                                                    label: "YouTube Music",
                                                    icon: youtubeMusicAppIcon,
                                                },
                                                {
                                                    url: backend.deezer_link,
                                                    label: "Deezer",
                                                    icon: deezerAppIcon,
                                                },
                                                {
                                                    url: backend.soundcloud_link,
                                                    label: "Soundcloud",
                                                    icon: soundcloudAppIcon,
                                                },
                                                {
                                                    url: backend.bandcamp_link,
                                                    label: "Bandcamp",
                                                    icon: bandcampAppIcon,
                                                },
                                            ].filter(i => i.url !== "")
                                            openPopup("StreamingLinks", { artist: backend, biography: biography, links })
                                        }
                                    : null,
                                target: "_blank"
                            },
                            {
                                onPress: () => openPopup("ShareArtist", { artist: backend, biography: biography }),
                                element: (
                                    <ShareElement 
                                    type="artist" 
                                    image={backend.picture} 
                                    title={`${backend.name}`} 
                                    />
                                )
                            },
                        ]}
                        />

                        <Heading
                        text={`Information`}
                        className="margin-top-32"
                        />

                        <div className="section-block">
                            <div className="padding-around">
                                {backend.rating_distribution && backend.rating_distribution.length > 0 &&
                                    <RatingsGraph ratings={backend.rating_distribution} />
                                }
                                
                                {(!backend.rating_distribution || backend.rating_distribution.length <= 0) &&
                                    <RatingDistributionLoader />
                                }
                            </div>
                            
                            <Suspense fallback={<div />}>
                                <ArtistInformationSection artist={backend} />
                            </Suspense>
                        </div>

                        {related && related.data && related.data.length > 0 &&
                            <Heading
                            text="You Might Also Like"
                            className="margin-top-32"
                            />
                        }
                        
                        {related && related.data && related.data.length > 0 &&
                            <div className="section-block padding-around-8">
                                {related.data.map((item, index) => (
                                    <SmallArtistItem key={`artisthome-related-${item.id}`} artist={item} />
                                ))}
                            </div>
                        }
                        
                        {isLoggedIn &&
                            <div className="flex-row margin-top-16">
                                <Link to={`/artist/${backend.id}/edit`} className="col-1">
                                    <Button
                                    showIcon
                                    iconSize={21}
                                    iconName="edit"
                                    iconColor="var(--black)"
                                    type="secondary"
                                    text="Contribute to this artist" 
                                    height={41}
                                    textStyle={{ fontSize: 14 }}
                                    />
                                </Link>
                                
                                <div
                                className="margin-left-12"
                                id="add-release-button"
                                >
                                    <Link to={`/album/new?artist=${backend.id}`}>
                                        <Button
                                        showIcon
                                        iconSize={20}
                                        iconName="contribute"
                                        iconColor="var(--black)"
                                        type="secondary"
                                        squareButton
                                        height={41}
                                        textStyle={{ fontSize: 14 }}
                                        />
                                    </Link>
                                </div>

                                <Tooltip
                                id="add-release-button"
                                text="Add release to artist"
                                offset={13}
                                />
                            </div>
                        }

                        <Link to={`${backend.url_slug}contributions`}>
                            <Button
                            className={"margin-top-12"}
                            iconColor="var(--black)"
                            type="secondary"
                            text="Contribution history"
                            height={41}
                            textStyle={{ fontSize: 14 }}
                            />
                        </Link>

                        {(isModerator || isDatabaseModerator) &&
                            <a 
                            href={`https://api.musicboard.app/admin/content/artist/${backend.id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            >
                                <Button
                                className='margin-top-12'
                                type="secondary"
                                text="Open in Admin"
                                height={41}
                                textStyle={{ fontSize: 14 }}
                                />
                            </a>
                        }

                        <PubliftAd
                        fuseId="trending_vrec_1"
                        className="margin-top-32"
                        type="vertical"
                        adChannel="Artist/RightVertical"
                        />
                        
                        <MiniFooter 
                        className={"margin-top-24"} 
                        idText={
                            isModerator ? 
                                `Artist ID: ${backend.id}\nDeezer ID: ${backend.deezer_id}\nUID: ${backend.uid}`
                            :
                                `Artist ID: ${backend.id}`
                        }
                        />
                    </StickyScrolling>
                </div>
            </div>
            
            <div style={{ height: 64 }} />
        </div>
    )
}
