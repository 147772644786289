let url, apiUrl, hostDomain, serverHostDomain, deezerHostUrl, deezerHostDomain, stripeApiKey, sentryDSN, yearlyPriceId, monthlyPriceId, corsUrl, sgwidgetToken, passwordAuthClientId, passwordAuthClientSecret, passwordAuthClientGrantType, socialAuthClientId, socialAuthClientSecret, socialAuthClientGrantType,
    stripeBasicMonthlyId, stripeBasicYearlyId, stripePremiumMonthlyId, stripePremiumYearlyId, stripeGiftBasicMonthlyId, stripeGiftBasicYearlyId, stripeGiftPremiumMonthlyId, stripeGiftPremiumYearlyId, stripeBasicMonthlyLink, stripeBasicYearlyLink, stripePremiumMonthlyLink, stripePremiumYearlyLink, stripeGiftBasicMonthlyLink, stripeGiftBasicYearlyLink, stripeGiftPremiumMonthlyLink, stripeGiftPremiumYearlyLink,
    stripeLifetimeOfferId, stripeLifetimeOfferLink;

const version = "7.0";

const bugReportUrl = "https://forms.gle/36mg2bVaAehZ8rqc8"
const feedbackUrl = "https://forms.gle/12dxR5mRFQa6DPHP8"
const mobileFeedbackUrl = "https://docs.google.com/forms/d/e/1FAIpQLSf59sKAE38ulXCjKEs3k1_pyMBighMF7pfX1uLexJ6vtxUisA/viewform?usp=sf_link"
const termsUrl = "https://musicboard.app/terms"
const privacyUrl = "https://musicboard.app/privacy"
const appStoreUrl = "https://apps.apple.com/us/app/musicboard-discover-music/id1503544789"
const googlePlayUrl = "https://play.google.com/store/apps/details?id=com.musicboard"
const noltUrl = "https://musicboard.nolt.io/"

const ogImageUrl = "https://musicboard.app/assets/og-image.png"
const twitterImageUrl = "https://musicboard.app/assets/twitter-image.png"

const monthlyPrice = '$4.99';
const monthlyPricePerYear = '$59.88';
const yearlyPriceDiscount = '$12.00';
const yearlyPrice = '$3.99';
const yearlyPriceTotal = '$47.88';

const maximizeAds = true;
const sidebarAds = false;

// Config variables for production
/* */
if (process.env.REACT_APP_STAGE === "production") {
    corsUrl = "https://cors.musicboard.app";
    url = "https://api.musicboard.app";
    apiUrl = "https://api.musicboard.app";

    hostDomain = "api.musicboard.app";
    serverHostDomain = "api.musicboard.app";

    deezerHostUrl = `${corsUrl}/https://api.deezer.com`;
    deezerHostDomain = "api.deezer.com";

    passwordAuthClientId = "smb1iEYkc4Y9KZzE48Uux5pgury3ieopBXuojjWq"
    passwordAuthClientSecret = "XpOJcwcBS9bngEjwWenMKxxJ23cpHn5ktHhw1GEMfpCcLAbFTYi8V6UrPrry5kUGEEunpoK06WH5LftzLcPvv6iqnvcPIp2sXCiLRZ6PZ96B0GHLYflKA4RPHmOsZioB"
    passwordAuthClientGrantType = "password"
    
    socialAuthClientId = "YBsOlaVGfLrTO12xwXMZW7eensl6R9ODA7TkYhNQ"
    socialAuthClientSecret = "5E2imeCZ15LEcVAylXjUgDkcQgDhUPLfmIvbDLh27S6M6ufeTHUC3PHAGoR7UQPuenb7ioKt7swbFPTfwHtOhpWprpryiQ4FtLqZlWVyGCbtx7yQG9pLKSXOm6V6XjrA"
    socialAuthClientGrantType = "convert_token"

    stripeApiKey = '';
    yearlyPriceId = '';
    monthlyPriceId = '';

    stripeBasicMonthlyId = "price_1R2c1TKBuX9JfPMyYI0GLBd7";
    stripeBasicYearlyId = "price_1R2c1iKBuX9JfPMyqG3HFlQ7";
    stripePremiumMonthlyId = "price_1R2c3DKBuX9JfPMyzAXlgK9R";
    stripePremiumYearlyId = "price_1R2c3QKBuX9JfPMyyh0OpgxI";

    stripeGiftBasicMonthlyId = "price_1R2c4VKBuX9JfPMyDdEt0cms";
    stripeGiftBasicYearlyId = "price_1R2c4wKBuX9JfPMyy2canqA2";
    stripeGiftPremiumMonthlyId = "price_1R2c5GKBuX9JfPMyR7VSTDn5";
    stripeGiftPremiumYearlyId = "price_1R2c5yKBuX9JfPMyJOHXJwVS";
    
    stripeBasicMonthlyLink = "https://buy.stripe.com/eVa5lk8MIfHm2GIaEE";
    stripeBasicYearlyLink = "https://buy.stripe.com/9AQaFEe72br6epq9AB";
    stripePremiumMonthlyLink = "https://buy.stripe.com/aEU7ts2ok7aQa9a146";
    stripePremiumYearlyLink = "https://buy.stripe.com/6oE6poe72eDibde4gj";
    stripeGiftBasicMonthlyLink = "https://buy.stripe.com/28o7tsaUQeDiftuaEI";
    stripeGiftBasicYearlyLink = "https://buy.stripe.com/4gw7ts9QMeDi0yAcMR";
    stripeGiftPremiumMonthlyLink = "https://buy.stripe.com/bIY2982okgLq4OQeV0";
    stripeGiftPremiumYearlyLink = "https://buy.stripe.com/dR6dRQ6EA52I5SU9AH";
    
    stripeLifetimeOfferId = "price_1R2c6TKBuX9JfPMy1jsxcOxF";
    stripeLifetimeOfferLink = "https://buy.stripe.com/cN21544wscva3KM9AI";

    sentryDSN = "https://0e8ea8f68ef748a1d2729a8c9343c270@o1076842.ingest.us.sentry.io/4508310149464064";

    sgwidgetToken = "33597dfae11f8462a7787fcaa5e6ab13";
}
// Config variables for development with production server
/* */
else {
    corsUrl = "http://localhost:5001";
    url = "http://localhost:5001" + "/" + "https://api.musicboard.app";
    apiUrl = "http://localhost:5001" + "/" + "https://api.musicboard.app";

    hostDomain = "api.musicboard.app";
    serverHostDomain = "api.musicboard.app";

    deezerHostUrl = `${corsUrl}/https://api.deezer.com`;
    deezerHostDomain = "api.deezer.com";

    passwordAuthClientId = "smb1iEYkc4Y9KZzE48Uux5pgury3ieopBXuojjWq"
    passwordAuthClientSecret = "XpOJcwcBS9bngEjwWenMKxxJ23cpHn5ktHhw1GEMfpCcLAbFTYi8V6UrPrry5kUGEEunpoK06WH5LftzLcPvv6iqnvcPIp2sXCiLRZ6PZ96B0GHLYflKA4RPHmOsZioB"
    passwordAuthClientGrantType = "password"
    
    socialAuthClientId = "YBsOlaVGfLrTO12xwXMZW7eensl6R9ODA7TkYhNQ"
    socialAuthClientSecret = "5E2imeCZ15LEcVAylXjUgDkcQgDhUPLfmIvbDLh27S6M6ufeTHUC3PHAGoR7UQPuenb7ioKt7swbFPTfwHtOhpWprpryiQ4FtLqZlWVyGCbtx7yQG9pLKSXOm6V6XjrA"
    socialAuthClientGrantType = "convert_token"

    stripeApiKey = '';
    yearlyPriceId = '';
    monthlyPriceId = '';

    stripeBasicMonthlyId = "price_1Plv91DAtj7oNfqrQcITcMhm";
    stripeBasicYearlyId = "price_1Plv9GDAtj7oNfqrjYQtx5Ou";
    stripePremiumMonthlyId = "price_1PlvAcDAtj7oNfqrBl96uIYJ";
    stripePremiumYearlyId = "price_1PlvB3DAtj7oNfqrncotnTEs";
    stripeGiftBasicMonthlyId = "price_1PlvBqDAtj7oNfqral5dwbZd";
    stripeGiftBasicYearlyId = "price_1PlvClDAtj7oNfqrzvds54HD";
    stripeGiftPremiumMonthlyId = "price_1PlvD4DAtj7oNfqrqfOYSLsF";
    stripeGiftPremiumYearlyId = "price_1PlvDVDAtj7oNfqrQbw4NKq7";
    
    stripeBasicMonthlyLink = "https://pay.musicboard.app/b/8wM03vf9SeFJ2eAbIY";
    stripeBasicYearlyLink = "https://pay.musicboard.app/b/8wM3fHf9Sapt9H2eVb";
    stripePremiumMonthlyLink = "https://pay.musicboard.app/b/3csg2t7Hqapt1awaEW";
    stripePremiumYearlyLink = "https://pay.musicboard.app/b/9AQeYpd1K5594mIbJ1";
    stripeGiftBasicMonthlyLink = "https://pay.musicboard.app/b/bIY03v4vefJNbPa9AU";
    stripeGiftBasicYearlyLink = "https://pay.musicboard.app/b/00g2bDaTCcxB06seVf";
    stripeGiftPremiumMonthlyLink = "https://pay.musicboard.app/b/eVa9E54ve0OTaL628u";
    stripeGiftPremiumYearlyLink = "https://pay.musicboard.app/b/4gw8A14ve1SX8CY6oL";

    stripeLifetimeOfferId = "price_1Q4ojjDAtj7oNfqrrCHFVkcy";
    stripeLifetimeOfferLink = "https://pay.musicboard.app/b/dR6aI9e5Obtx9H26oM";

    sentryDSN = "https://0e8ea8f68ef748a1d2729a8c9343c270@o1076842.ingest.us.sentry.io/4508310149464064";

    sgwidgetToken = "33597dfae11f8462a7787fcaa5e6ab13";
}

// Config variables for development
/* 
else {
    corsUrl = "http://localhost:5001";
    url = 'http://localhost:8000';
    apiUrl = "http://localhost:8000";

    hostDomain = "localhost:8000";
    serverHostDomain = "api.musicboard.app";

    deezerHostUrl = `${corsUrl}/https://api.deezer.com`;
    deezerHostDomain = "api.deezer.com";

    passwordAuthClientId = "shuqEf75A2mleTzul2izcLPT34DqvyPHSIB2UFmU"
    passwordAuthClientSecret = "ePNYqL9fo2AIWvff37BFG9xxNtgzIW7Aw6XrJfnrwAXhkx7CJGtGQhyZ4tCrGhzHFPgckBmIddR1YaxMdUsy3rZoMZ29tX33hKU7KEGLigb1cihWLPDqKP6x5JOoiBvT"
    passwordAuthClientGrantType = "password"

    socialAuthClientId = "NsiVTBDdbX17kQPIla0qa0hQiGuf8ERCr0tytMIZ"
    socialAuthClientSecret = "MRelXBb7v7agJ1133mJO9YDwvzYCmmpNfQRuVnGohk1LqZpDyIFuAPxOQw0TuEa7fwlL15HS0z5Tw5AQDWn4iB4ZqkDc8VreODnHAfhmlEmAMQCPnqbBe3NwYlm2Rxok"
    socialAuthClientGrantType = "convert_token"

    stripeApiKey = '';
    yearlyPriceId = '';
    monthlyPriceId = '';

    stripeBasicMonthlyId = "price_1PheDGDAtj7oNfqrfrnbOvuu";
    stripeBasicYearlyId = "price_1PheDVDAtj7oNfqr1DOXpKhH";
    stripePremiumMonthlyId = "price_1PheG1DAtj7oNfqrC2D9kujZ";
    stripePremiumYearlyId = "price_1PheGDDAtj7oNfqrl4u0cFxP";
    stripeGiftBasicMonthlyId = "price_1PheGoDAtj7oNfqrx1hNlIHi";
    stripeGiftBasicYearlyId = "price_1PheH8DAtj7oNfqrR8daCUJk";
    stripeGiftPremiumMonthlyId = "price_1PheHODAtj7oNfqrDJHuaK5s";
    stripeGiftPremiumYearlyId = "price_1PheHhDAtj7oNfqrJshJ7iwG";

    stripeBasicMonthlyLink = "https://pay.musicboard.app/b/test_28ocN41Xs6aI4U0008";
    stripeBasicYearlyLink = "https://pay.musicboard.app/b/test_eVa14matY9mUgCIfZ7";
    stripePremiumMonthlyLink = "https://pay.musicboard.app/b/test_9AQ7sK9pU9mU1HO28i";
    stripePremiumYearlyLink = "https://pay.musicboard.app/b/test_dR628qeKegPm0DKbIT";
    stripeGiftBasicMonthlyLink = "https://pay.musicboard.app/b/test_aEUcN4cC6cz6cms14g";
    stripeGiftBasicYearlyLink = "https://pay.musicboard.app/b/test_aEUeVc0TogPm9ag6oB";
    stripeGiftPremiumMonthlyLink = "https://pay.musicboard.app/b/test_5kAfZgby2cz69ag8wK";
    stripeGiftPremiumYearlyLink = "https://pay.musicboard.app/b/test_cN2cN4fOicz69agfZd";

    stripeLifetimeOfferId = "price_1Q4pvRDAtj7oNfqrAy3RhDQU";
    stripeLifetimeOfferLink = "https://pay.musicboard.app/b/test_fZe8wO7hMfLi728fZe";

    sentryDSN = "https://0e8ea8f68ef748a1d2729a8c9343c270@o1076842.ingest.us.sentry.io/4508310149464064";

    sgwidgetToken = "33597dfae11f8462a7787fcaa5e6ab13";
}
*/

export {
    url, 
    apiUrl,
    hostDomain,
    serverHostDomain,
    deezerHostUrl,
    deezerHostDomain,

    stripeApiKey, 

    stripeBasicMonthlyId,
    stripeBasicYearlyId,
    stripePremiumMonthlyId,
    stripePremiumYearlyId,
    stripeGiftBasicMonthlyId,
    stripeGiftBasicYearlyId,
    stripeGiftPremiumMonthlyId,
    stripeGiftPremiumYearlyId,
    
    stripeBasicMonthlyLink,
    stripeBasicYearlyLink,
    stripePremiumMonthlyLink,
    stripePremiumYearlyLink,
    stripeGiftBasicMonthlyLink,
    stripeGiftBasicYearlyLink,
    stripeGiftPremiumMonthlyLink,
    stripeGiftPremiumYearlyLink,

    stripeLifetimeOfferId,
    stripeLifetimeOfferLink,

    sentryDSN, 
    yearlyPriceId, 
    monthlyPriceId, 
    corsUrl,
    sgwidgetToken,
    bugReportUrl,
    feedbackUrl,
    mobileFeedbackUrl,
    termsUrl,
    privacyUrl,
    appStoreUrl,
    googlePlayUrl,
    ogImageUrl,
    twitterImageUrl,
    monthlyPrice,
    monthlyPricePerYear,
    yearlyPriceDiscount,
    yearlyPrice,
    yearlyPriceTotal,
    version,
    passwordAuthClientId,
    passwordAuthClientSecret,
    passwordAuthClientGrantType,
    socialAuthClientId,
    socialAuthClientSecret,
    socialAuthClientGrantType,

    noltUrl,

    maximizeAds,
    sidebarAds,
};
