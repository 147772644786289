import styles from './SidebarAds.module.css'

import React from 'react'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { sidebarAds } from '../../config'

import PubliftAd from '../../components/PubliftAd'

const hiddenPaths = [
    ["/about"],
    ["/faq/storage"],
    ["/contact"],
    ["/contributors/artists"],
    ["/contributors"],
    ["/contributors/releases"],
    ["/album/new"],
    ["/artist/new"],
    ["/list/new"],
    ["/new"],
    ["/faq"],
    ["/groups"],
    ["/guidelines"],
    ["/onboarding"],
    ["/onboarding/personal"],
    ["/onboarding/genres"],
    ["/onboarding/albums"],
    ["/onboarding/users"],
    ["/onboarding/features"],
    ["/patch-notes"],
    ["/privacy"],
    ["/download"],
    ["/pro"],
    ["/reset-password"],
    ["/settings"],
    ["/settings/preferences"],
    ["/settings/billing"],
    ["/guidelines"],
    ["/terms"],
    ["/pro/complete"],
    ["/verify-email"],
    ["/album", "/edit"],
    ["/artist", "/edit"],
    ["/list", "/edit"],
]

const hiddenLoginPaths = [
    ["/lists/following"],
    ["/reviews/following"],
    ["/ratings/following"],
]

export default function SidebarAds() {
    //General
    const location = useLocation()

    const isBigScreen = useMediaQuery({ maxWidth: 1532 })

    const skipTaglessUnits = () => {
        for (let i = 0; i < hiddenPaths.length; i++) {
            const path = hiddenPaths[i]
            if (location.pathname.includes("/groups")) {
                return true
            } else if (path.length === 1 && location.pathname === path[0]) {
                return true
            } else if (path.length === 2 && location.pathname.includes(path[0]) && location.pathname.includes(path[1])) {
                return true
            }
        }
        for (let i = 0; i < hiddenLoginPaths.length; i++) {
            const path = hiddenLoginPaths[i]
            if (path.length === 1 && location.pathname === path[0] && !this.props.isLoggedIn) {
                return true
            }
        }
    }

    if (skipTaglessUnits()) {
        return null
    }

    if (isBigScreen) {
        return null
    }

    if (!sidebarAds) {
        return null
    }

    return (
        <>
            <div className={`${styles.sidebarAd} ${styles.left}`}>
                <PubliftAd
                className={styles.ad}
                fuseId="vrec_lhs_1"
                type="full"
                />
            </div>

            <div className={`${styles.sidebarAd} ${styles.right}`}>
                <PubliftAd
                className={styles.ad}
                fuseId="vrec_rhs_1"
                type="full"
                />
            </div>
        </>
    )
}
